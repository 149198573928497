<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group
        label="Tarih"
        label-for="mdate"
      >
        <b-form-datepicker
          id="edate"
          v-model="meal[0].mdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <div
        v-for="(item,key) in meal"
        :key="key"
      >
        <b-form-group
          :label="(key + 1) + '. Yemek'"
          :label-for="'meal_' + key"
        >
          <b-form-input
            v-model="item.title"
            placeholder="Yemek"
          />
        </b-form-group>
      </div>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BFormInput,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Edit',
  components: {
    BFormInput,
    BForm,
    BCard,
    BFormDatepicker,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    meal() {
      return this.$store.getters['meals/getMeals']
    },
    saveStatus() {
      return this.$store.getters['meals/getMealSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getMeals()
    this.getItems()
  },
  methods: {
    getItems() {
      this.$store.dispatch('meals/itemsList')
    },
    getMeals() {
      this.$store.dispatch('meals/mealsList', {
        where: {
          'com_portal_meals.mdate': this.$route.params.id,
        },
      })
    },
    addMeal() {
      this.meal.mealList.push({ title: null })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.meal.submitStatus = true
      this.$store.dispatch('meals/mealSave', this.meal)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
